
import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    watch,
} from "vue";
import IconPlus from "@/components/icons/IconPlus2.vue";
import IconCheck from "@/components/icons/IconCheckbox.vue";
import { storeToRefs } from "pinia";
import { useCustomerNormStore } from "@/store/customerNormRelation";
import { CustomerListItemInterface } from "@/store/customer/interfaces";
import { useHelpersStore } from "@/store/helpers";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { cloneData } from "@/common/cloneData";

export default defineComponent({
    name: "CustomerOverview",
    components: { IconPlus, IconCheck, LoadingAnimation },
    setup() {
        const maxDisplayedCustomers = ref(6);
        const {
            chosenCustomers,
            customerSimpleList,
            customerParams,
            customerPagination,
            maxCustomers,
        } = storeToRefs(useCustomerNormStore());
        const { loadingIds } = storeToRefs(useHelpersStore());
        const { fetchCustomerSimpleList } = useCustomerNormStore();

        const { setLoadingId, removeLoadingId } = useHelpersStore();

        var typingTimer: ReturnType<typeof setTimeout>;
        watch(
            () => [customerParams.value.search],
            () => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(async () => {
                    getCustomers(true);
                }, 1000);
            }
        );

        const isLoading = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "fetch_customers_loading"
            );
        });

        const filteredCustomers = computed(() => {
            let customers = cloneData(customerSimpleList.value);
            chosenCustomers.value.forEach(
                (chosenCustomer: CustomerListItemInterface) => {
                    for (let index = 0; index < customers.length; index++) {
                        if (customers[index].id == chosenCustomer.id) {
                            customers.splice(index, 1);
                            index--;
                        }
                    }
                }
            );
            return customers;
        });
        function addCustomer(customer: CustomerListItemInterface): void {
            if (chosenCustomers.value.length != maxDisplayedCustomers.value) {
                chosenCustomers.value.push(customer);
            }
        }
        function removeCustomer(index: number): void {
            chosenCustomers.value.splice(index, 1);
        }
        async function getCustomers(reset: boolean): Promise<void> {
            if (reset) {
                customerPagination.value.page = 1;
                customerPagination.value.endOfList = false;
            }
            if (!customerPagination.value.endOfList) {
                setLoadingId("fetch_customers_loading");
                await fetchCustomerSimpleList();
                removeLoadingId("fetch_customers_loading");
            }
        }

        function handleScroll() {
            if (!customerPagination.value.endOfList) {
                let element = document.getElementById(
                    "available_customer_list"
                );
                if (
                    element &&
                    element?.scrollTop + element?.clientHeight >=
                        element?.scrollHeight
                ) {
                    customerPagination.value.page++;
                    getCustomers(false);
                }
            }
        }
        onMounted(() => {
            customerSimpleList.value = [];
            customerParams.value.sort == "index";
            getCustomers(true);
            const list: HTMLElement | null = document.getElementById(
                "available_customer_list"
            );

            list?.addEventListener("scroll", handleScroll);
        });

        onUnmounted(() => {
            const list: HTMLElement | null = document.getElementById(
                "available_customer_list"
            );
            list?.removeEventListener("scroll", handleScroll);
        });
        return {
            filteredCustomers,
            addCustomer,
            removeCustomer,
            maxDisplayedCustomers,
            customerSimpleList,
            chosenCustomers,
            isLoading,
            maxCustomers,
        };
    },
});
