
import IconArrow from "@/components/icons/IconArrowBigBottom.vue";
import ListItem from "./ListItemContainer.vue";
import ListItemMobile from "./ListItemMobileContainer.vue";
import { computed, defineComponent, onMounted, onUnmounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useCustomerNormStore } from "@/store/customerNormRelation";
import { useHelpersStore } from "@/store/helpers";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";

export default defineComponent({
    name: "itemList",
    emits: ["openDetails"],
    components: {
        IconArrow,
        ListItem,
        ListItemMobile,
        LoadingAnimation,
    },
    setup() {
        const { normList, chosenCustomers, customerNormPagination, params } =
            storeToRefs(useCustomerNormStore());
        const { loadingIds } = storeToRefs(useHelpersStore());
        const { fetchNormList } = useCustomerNormStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();

        var typingTimer: ReturnType<typeof setTimeout>;
        watch(
            () => [
                params.value?.search,
                params.value?.policy_ids?.length,
                params.value?.type_ids?.length,
            ],
            () => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(async () => {
                    const policiesFilterLength =
                        params.value?.policy_ids?.length ?? (0 as number);
                    const typeFilterLength =
                        params.value.type_ids?.length ?? (0 as number);

                    if (policiesFilterLength <= 3 && typeFilterLength <= 3)
                        fetchNorms(true);
                }, 1000);
            }
        );

        const isLoading = computed(() => {
            return loadingIds.value.find(
                (element) => element.name == "fetch_norms_loading"
            );
        });

        const customerHeaderColCount = computed(() => {
            return `grid-template-columns: repeat(${chosenCustomers.value.length}, minmax(0, 1fr));`;
        });

        function setSort(filter: string, customerId: string | number | null) {
            if (params.value?.sort == filter) {
                if (params.value?.sort[0] != "-") {
                    if (customerId) params.value.customer_id = customerId;
                    params.value.sort = "-" + params.value?.sort;
                } else {
                    if (customerId == params.value?.customer_id)
                        params.value.sort = params.value?.sort.substring(1);
                    if (customerId) params.value.customer_id = customerId;
                    customerNormPagination.value.page = 1;
                    customerNormPagination.value.endOfList = false;
                }
            } else {
                if (customerId) params.value.customer_id = customerId;
                params.value.sort = filter;
            }

            fetchNorms(true);
        }
        async function fetchNorms(reset: boolean): Promise<void> {
            if (reset) {
                customerNormPagination.value.page = 1;
                customerNormPagination.value.endOfList = false;
            }
            if (!customerNormPagination.value.endOfList) {
                setLoadingId("fetch_norms_loading");
                await fetchNormList();
                removeLoadingId("fetch_norms_loading");
            }
        }
        function handleScroll() {
            if (!customerNormPagination.value.endOfList) {
                let element: HTMLElement | null = document.getElementById(
                    "customer_norm_list"
                ) as HTMLElement;
                if (
                    element?.scrollTop + element?.clientHeight >=
                    element?.scrollHeight
                ) {
                    customerNormPagination.value.page++;
                    fetchNorms(false);
                }
            }
        }

        onMounted(() => {
            normList.value = [];
            fetchNorms(true);
            const list: HTMLElement | null =
                document.getElementById("customer_norm_list");

            list?.addEventListener("scroll", handleScroll);
        });

        onUnmounted(() => {
            const list: HTMLElement | null =
                document.getElementById("customer_norm_list");
            list?.removeEventListener("scroll", handleScroll);
        });
        return {
            setSort,
            normList,
            params,
            chosenCustomers,
            isLoading,
            customerHeaderColCount,
        };
    },
});
